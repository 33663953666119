#file-upload-form {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#file-upload-input {
  display: none;
}

#file-upload-label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#file-upload-label-disabled {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #d5dbdb;
  background-color: #eaeded;
}

#file-upload-label.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#boldsign-iframe {
  border: 0;
}