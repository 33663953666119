.fc-event-start {
  &.fc-event-pad-half-day-start { margin-left: 6vw !important; }
}
.fc-event-end {
  &.fc-event-pad-half-day-end { margin-right: 6vw !important; }
}

.fc-event {
  &.fc-event-show-availability {

    border-radius: 15vw;

    @media screen and (min-device-width: 1101px) {
      margin-right: 4.5vw !important;
      margin-left: 4.5vw !important;
    }

    @media screen and (max-device-width: 1100px) {
      margin-right: 1.5vw !important;
      margin-left: 1.5vw !important;
    }

  }

}
